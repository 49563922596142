/* Lawyer Justice HTML5 Template */

@media only screen and (max-width: 1320px) {
  .main-header .top-bar .lang-box .lang-list {
    left: auto;
    right: 0px;
  }

  .main-header .top-bar .lang-box .lang-list::before {
    left: auto;
    right: 25px;
  }
}

@media only screen and (max-width: 1140px) {
  .page-info {
    .display-flex .tz-left-50 {
      width: 25%;
    }
    .display-flex .tz-right-50 {
      width: 75%;
    }
  }

  .main-header.top-bar .lang-box .lang-list {
    left: auto;
    right: 0;
    &:before {
      right: 25px;
      left: auto;
    }
  }

  .checkout-section .cart-table {
    min-width: 0;
  }

  .cart-table tbody tr td,
  .cart-table thead tr th {
    min-width: 0;
    padding: 15px 10px !important;
    font-size: 14px;
  }

  .cart-table tbody tr .prod-column .column-box h3 {
    font-size: 14px;
  }

  .cart-table .cart-header {
    font-size: 14px;
  }

  .cart-table tbody tr td.hikashop_cart_product_image_value {
    img {
      width: 50px;
    }
  }

  .hikashop_product_bottom_part.show_tabular .hikashop_tabs_ul li {
    padding: 10px 20px;
    font-size: 14px;
  }

  .main-header .lower-section .outer-box {
    padding: 0px 180px 0px 20px;
  }

  .main-header.lower-section.outer-box > .container > div {
    padding: 0 20px;
  }

  .main-menu .level0 > li {
    margin-right: 10px;
  }

  .header-lower .logo,
  .header-lower .main-menu {
    float: none !important;
  }

  .main-menu .navigation > li {
    margin-right: 10px;
  }

  .main-header .link-box {
    right: 20px;
  }

  .sponsors-section .owl-nav .owl-prev {
    left: 10px;
  }

  .sponsors-section .owl-nav .owl-next {
    right: 10px;
  }

  .text-carousel-outer {
    margin-bottom: 15px;
  }

  .our-experience .timeline-outer {
    padding-left: 0px;
  }

  .our-experience .timeline-outer .left-line {
    display: none;
  }

  .error-section .image-box img {
    max-width: 70%;
  }

  .attorney-single .basic-details .contact-info {
    width: 100%;
    margin-bottom: 10px;
  }

  .attorney-single .basic-details .member-info {
    margin-bottom: 10px;
  }

  .attorney-single .basic-details .theme-btn {
    margin-bottom: 10px;
  }

  .blog-details .post-bottom {
    display: block;
  }

  .blog-details .post-bottom .post-info,
  .blog-details .post-bottom .share-options {
    width: 100%;
    margin-bottom: 5px;
    text-align: left;
    &:after {
      clear: both;
      display: block;
      content: '';
    }
  }

  .info-header {
    .TzArticleExtraField {
      float: left !important;
    }
  }
}

@media only screen and (min-width: 768px) {
  .main-menu .navigation > li > ul,
  .main-menu .navigation > li > ul > li > ul {
    display: block !important;
    visibility: hidden;
    opacity: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .main-header.middle-bar .logo-column {
    position: relative;
  }

  .main-header.lower-section .outer-box {
    padding: 0px 20px;
    height: 90px;
  }

  .main-menu {
    //display: none;
  }

  .main-header .nav-toggler {
    display: block;
    right: 20px;
  }

  .main-header .link-box {
    right: auto;
    left: 20px;
  }

  .main-header .top-bar .top-right {
    float: left;
  }

  .two-column-fluid .whyus-column .inner-box {
    padding-left: 15px;
  }

  .two-column-fluid .whyus-column .icon-block {
    padding-left: 70px;
  }

  .two-column-fluid .whyus-column .icon-block .icon {
    left: -20px;
  }

  .consult-section .auto-container {
    padding-left: 15px;
  }

  .consult-section .man-image {
    display: none;
  }

  .error-section .image-box {
    display: none;
  }

  .error-section .text-content {
    max-width: 100%;
    padding: 0px 0px 70px;
  }

  .checkout-section .table-outer {
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {

  .padd-bott-70-sm {
    padding-bottom: 70px;
  }

  .clear-both-sm {
    clear: both;
    display: block;
  }

  .main-header.top-bar .lang-box {
    margin-left: 10px;
  }

  .main-header .nav-toggler {
    padding: 25px 0;
  }

  .menu-static {
    position: static;
    width: 100%;
    left: 0;
    top: 0;
  }

  .custom-mod-static {
    position: absolute;
    width: auto;
    left: 20px;
    top: 20px;
  }

  .main-menu {
    position: static;
    min-height: 90px;
    z-index: 99999;
    .dropdown-menu li {
      border: none;
    }
    .navbar-collapse {
      //.plazart-megamenu {
      position: absolute;
      top: 100%;
      left: 0;
      border: none;
      background: #223555;
      width: 100%;
      max-height: 420px;
      z-index: 9999999;
      //}
    }
    .level0 {
      width: 100%;
      > li {
        width: 100%;
        margin: 0;
        padding: 0;
        &:before {
          content: none;
        }
        > a {
          //
          //padding: 10px 15px 10px 20px;
          //border-width: 1px 0;
          //border-style: solid;
          //border-color: rgba(255, 255, 255, 0.3);
          //font-size: 12px;
        }
        &.open {
          .caret:before {
            content: '\f068';
          }
        }
        .caret {
          display: inline-block !important;
          float: right;
          height: auto;
          width: auto;
          border: none;
          margin: 0;
          &:before {
            content: '\f0c9';
            font-family: FontAwesome;
            font-size: 14px;
            color: #ffffff;
            border: 1px solid #fff;
            padding: 7px 9px;
            .transition(all 0.3s ease 0s);
          }
        }
        a {
          text-transform: uppercase;
          padding: 10px 15px 10px 20px;
          border-width: 1px 0;
          border-style: solid;
          border-color: rgba(255, 255, 255, 0.3);
          font-size: 12px;
          background: transparent;
        }
        > .dropdown-menu {
          position: relative;
          display: none;
          background: transparent;
          border: none;
          li > .dropdown-menu {
            position: relative;
            display: none;
            background: transparent;
            border: none;
          }
        }
      }
    }
  }

  .law-section .tz_portfolio_plus_image {
    margin: 0;
  }

  .law-section .law-upper {
    .tz_extrafield-value {
      float: none !important;
    }
  }

  .product-details .basic-details .hikashop_add_wishlist {
    display: block;
    padding-left: 0;
  }

  .hikashop_checkout_address_left_part {
    margin-bottom: 30px;
  }

  .cart-table tbody tr td {
    .hikashop_product_cart_image {
      width: 50px;
    }
  }

  .cart-table tbody tr .prod-column .column-box h3 {
    margin: 0;
    padding: 0 !important;
  }

  .cart-table tbody .available-info {
    font-size: 12px;
    padding-left: 35px;
    .icon {
      width: 25px;
      height: 25px;
      line-height: 25px;
      font-size: 14px;
    }
  }

  .cart-table tbody tr .qty {
    width: 100px;
    .quantity-spinner {
      padding: 5px 0px 5px 15px;
    }
  }

  .cart-table tbody tr .remove-btn {
    font-size: 14px;
    .fa {
      font-size: 18px;
    }
  }
}

@media only screen and (max-width: 767px) {

  .main-slider .tp-caption {
    display: none !important;
  }

  .practice-column .lower-content {
    min-height: 100px;
  }

  .law-section .law-upper .specs-box {
    padding: 30px 20px;
  }

  .accordion-box .accordion .accord-btn::after {
    right: 0px;
  }

  .list-view .featured-news-column .image-box,
  .list-view .featured-news-column .right-content {
    width: 100%;
  }

  .list-view .featured-news-column .content {
    padding: 30px 20px;
  }

  .list-view .featured-news-column .date {
    left: 30px;
    top: -80px;
  }

  .two-column-fluid .testimonial-column .carousel-box {
    padding: 0px 10px 0px 100px;
  }

  table {
    tbody td, th {
      display: block !important;
      width: 100% !important;
      text-align: center;
      border-bottom: 1px solid #cccccc;
      img {
        width: auto !important;
      }
    }
    tfoot {
      td {
        display: inline-block !important;
        &.tz-add-wish-list {
          display: block !important;
          padding-top: 30px !important;
        }
      }
    }
  }

  .cart-section {
    .tz-process {
      text-align: center;
      padding: 15px 0 0 !important;
      .hikashop_cart_button {
        margin: 0 0 15px 0;
      }
    }
    .hikashop_showcart_infos {
      .tz-action {
        text-align: center;
        .theme-btn {
          margin: 0 10px 5px;
        }
      }
      .display-flex {
        display: block;
        > div {
          width: 100% !important;
          margin-bottom: 30px;
        }
      }
    }
  }

  .cart-table tbody tr .prod-column .column-box {
    padding: 0;
    h3 {
      padding: 0 !important;
      font-size: 20px;
      text-align: center;
    }
  }

  .cart-table tbody .available-info {
    padding: 0;
    .icon {
      position: relative;
      top: 0;
      margin-right: 5px;
    }
    br {
      display: none;
    }
  }

  .cart-section .hikashop_cart_product_price_value .hikashop_product_price {
    display: inline-block;
  }

  .cart-table tbody tr .qty {
    .input-group {
      margin: 0 auto;
      width: 100px;
    }
    .input-group-btn-vertical {
      line-height: 1.2em;
    }
  }

  .cart-section .hika_show_cart_total td.hika_show_cart_total_price {
    float: right;
  }
}

@media only screen and (max-width: 599px) {

  .footer-bottom {
    font-size: 14px;
    text-align: center;
  }

  .custom-mod-static {
    left: 10px;
  }

  .main-header.lower-section.outer-box > .container > div {
    padding: 0 10px;
  }

  .footer-upper .links-widget li {
    width: 100%;
  }

  .jsn-uniform.jsn-master .jsn-bootstrap .jsn-row-container.row-fluid {
    margin-bottom: 0;
  }

  .jsn-uniform.jsn-master .span12,
  .jsn-uniform.jsn-master .span4,
  .jsn-uniform.jsn-master .span6 {
    width: 100%;
    margin-bottom: 30px;
  }

  .main-header .outer-box .clearfix.hidden {
    display: block !important;
  }

  .main-header.middle-bar .info-column {
    width: 100%;
    margin: 0px;
    text-align: center;
  }

  .module-title,
  .styled-heading h2,
  .welcome-section .text-header h2,
  .styled-heading h2,
  .main-header.middle-bar .info-column h3,
  .law-section .law-upper .specs-box h2,
  .featured-news-column h3,
  .blog-details .styled-heading h2 {
    font-size: 22px;
  }

  .accordion-box.style-three .accordion .accord-btn .panel-title,
  .accordion-box .accordion .accord-btn,
  .accordion-box .accordion .accord-btn h4 {
    font-size: 14px;
  }

  .page-title h1 {
    font-size: 26px;
    line-height: 40px;
  }

  section.page-title {
    padding: 135px 0px 85px;
  }

  .error-section .bigger-text {
    font-size: 100px;
    line-height: 100px;
  }

  .error-section .theme-btn {
    margin-bottom: 10px;
  }

  .page-info .pull-left,
  .page-info .pull-right {
    width: 100%;
    margin-bottom: 10px;
  }

  .tabs-box.style-one .tab-buttons .tab-btn {
    display: block;
    margin: 0px 0px 15px;
  }

  .prod-tabs .tab-btns .tab-btn {
    display: block;
    border: 2px solid #e0e0e0;
    margin-bottom: 10px;
    float: none;
    width: 100%;
    text-align: center;
  }

  .two-column-fluid .testimonial-column .carousel-box {
    padding: 0px 15px;
  }

  .two-column-fluid .testimonial-column .quote-icon {
    display: none;
  }

  .itemRelated .display-flex {
    display: block;
  }

  .itemRelated .tz-media {
    margin-bottom: 30px;
    margin-right: 0;
  }

  .itemRelated .tz-content,
  .itemRelated .tz-media {
    width: 100%;
    //margin: 0;
  }

  .itemRelated .tz-content .itemRelTitle {
    font-size: 22px;
  }

  .hikashop_product_left_part {
    margin-bottom: 20px;
  }

  .hikashop_product_bottom_part.show_tabular .hikashop_tabs_ul li {
    display: block;
    border: 2px solid #e0e0e0;
    margin-bottom: 10px;
    float: none;
    width: 100%;
    text-align: center;
  }

  .checkout-section .ship-options .option-block .radio-block, .checkout-section .payment-options .option-block .radio-block {
    .hikashop_checkout_shipping_description,
    .hikashop_checkout_payment_description,
    .hikashop_checkout_payment_image {
      display: block;
      text-align: left;
    }
  }

}

@media only screen and (max-width: 479px) {
  .page-info {
    .display-flex {
      display: block;
      > div {
        width: 100%;
      }
      .text-right {
        text-align: left;
      }
    }
  }

  .main-header .top-bar .search-box {
    display: none;
  }

  .main-header .top-bar .lang-box {
    margin: 0px;
    width: 100%;
  }

  .main-header .link-box {
    left: 10px;
  }

  .lower-section .nav-toggler {
    right: 10px;
  }

  .main-header .top-bar .lang-box .lang-list {
    right: auto;
    left: 0px;
  }

  .blog-details .comments-area .reply-comment {
    margin-left: 0px;
  }

  .blog-details .comments-area .comment-box strong,
  .blog-details .comments-area .comment-box .time {
    width: 100%;
  }

  .product-details .basic-details .item-quantity {
    margin-bottom: 15px;
  }

  .prod-tabs .reviews-container .review-box {
    padding-left: 0px;
  }

  .prod-tabs .reviews-container .rev-thumb {
    position: relative;
    margin-bottom: 20px;
  }

  .cart-table tbody tr .prod-column .column-box h3 {
    //padding-top: 10px !important;
  }

  .two-column-fluid .testimonial-column {
    padding: 70px 45px 70px 30px;
  }
}

@media (max-width: 399px) {
  .main-header.top-bar .top-right {
    padding: 0 10px;
  }

  .main-header.top-bar .top-left {
    padding: 10px;
  }

  .main-header.top-bar .lang-box {
    margin-left: 0;
  }

  .main-header.top-bar .search-box button {
    width: 20px;
    right: 10px;
  }

  .main-header.top-bar .search-box input[type="text"] {
    padding: 10px 30px 10px 0;
    min-width: 0;
  }
}