/*
  Flaticon icon font: Flaticon
  Creation date: 16/03/2016 05:26
  */

@font-face {
  font-family: "Flaticon";
  src: url("../../../fonts/flaticon/flaticon.eot");
  src: url("../../../fonts/flaticon/flaticon.eot?#iefix") format("embedded-opentype"),
  url("../../../fonts/flaticon/flaticon.woff") format("woff"),
  url("../../../fonts/flaticon/flaticon.ttf") format("truetype"),
  url("../../../fonts/flaticon/flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../../../fonts/flaticon/flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
  font-style: normal;
}

.flaticon-add:before { content: "\f100"; }
.flaticon-agenda:before { content: "\f101"; }
.flaticon-arrow:before { content: "\f102"; }
.flaticon-arrows:before { content: "\f103"; }
.flaticon-arrows-1:before { content: "\f104"; }
.flaticon-arrows-10:before { content: "\f105"; }
.flaticon-arrows-11:before { content: "\f106"; }
.flaticon-arrows-12:before { content: "\f107"; }
.flaticon-arrows-13:before { content: "\f108"; }
.flaticon-arrows-14:before { content: "\f109"; }
.flaticon-arrows-15:before { content: "\f10a"; }
.flaticon-arrows-16:before { content: "\f10b"; }
.flaticon-arrows-17:before { content: "\f10c"; }
.flaticon-arrows-18:before { content: "\f10d"; }
.flaticon-arrows-19:before { content: "\f10e"; }
.flaticon-arrows-2:before { content: "\f10f"; }
.flaticon-arrows-20:before { content: "\f110"; }
.flaticon-arrows-21:before { content: "\f111"; }
.flaticon-arrows-3:before { content: "\f112"; }
.flaticon-arrows-4:before { content: "\f113"; }
.flaticon-arrows-5:before { content: "\f114"; }
.flaticon-arrows-6:before { content: "\f115"; }
.flaticon-arrows-7:before { content: "\f116"; }
.flaticon-arrows-8:before { content: "\f117"; }
.flaticon-arrows-9:before { content: "\f118"; }
.flaticon-back:before { content: "\f119"; }
.flaticon-bag:before { content: "\f11a"; }
.flaticon-bag-1:before { content: "\f11b"; }
.flaticon-bag-2:before { content: "\f11c"; }
.flaticon-bag-3:before { content: "\f11d"; }
.flaticon-baggage:before { content: "\f11e"; }
.flaticon-black:before { content: "\f11f"; }
.flaticon-briefcase:before { content: "\f120"; }
.flaticon-business:before { content: "\f121"; }
.flaticon-calendar:before { content: "\f122"; }
.flaticon-calendar-1:before { content: "\f123"; }
.flaticon-calendar-2:before { content: "\f124"; }
.flaticon-card:before { content: "\f125"; }
.flaticon-case:before { content: "\f126"; }
.flaticon-caution:before { content: "\f127"; }
.flaticon-circle:before { content: "\f128"; }
.flaticon-circle-1:before { content: "\f129"; }
.flaticon-circle-2:before { content: "\f12a"; }
.flaticon-circle-3:before { content: "\f12b"; }
.flaticon-circle-4:before { content: "\f12c"; }
.flaticon-circle-5:before { content: "\f12d"; }
.flaticon-circle-6:before { content: "\f12e"; }
.flaticon-circle-7:before { content: "\f12f"; }
.flaticon-circle-8:before { content: "\f130"; }
.flaticon-circle-9:before { content: "\f131"; }
.flaticon-clock:before { content: "\f132"; }
.flaticon-clock-1:before { content: "\f133"; }
.flaticon-clock-2:before { content: "\f134"; }
.flaticon-clock-3:before { content: "\f135"; }
.flaticon-clock-4:before { content: "\f136"; }
.flaticon-clock-5:before { content: "\f137"; }
.flaticon-close:before { content: "\f138"; }
.flaticon-cogwheel:before { content: "\f139"; }
.flaticon-cogwheel-1:before { content: "\f13a"; }
.flaticon-cogwheel-2:before { content: "\f13b"; }
.flaticon-cogwheel-3:before { content: "\f13c"; }
.flaticon-cogwheel-4:before { content: "\f13d"; }
.flaticon-commerce:before { content: "\f13e"; }
.flaticon-commerce-1:before { content: "\f13f"; }
.flaticon-commerce-10:before { content: "\f140"; }
.flaticon-commerce-11:before { content: "\f141"; }
.flaticon-commerce-2:before { content: "\f142"; }
.flaticon-commerce-3:before { content: "\f143"; }
.flaticon-commerce-4:before { content: "\f144"; }
.flaticon-commerce-5:before { content: "\f145"; }
.flaticon-commerce-6:before { content: "\f146"; }
.flaticon-commerce-7:before { content: "\f147"; }
.flaticon-commerce-8:before { content: "\f148"; }
.flaticon-commerce-9:before { content: "\f149"; }
.flaticon-cone:before { content: "\f14a"; }
.flaticon-connection:before { content: "\f14b"; }
.flaticon-construction:before { content: "\f14c"; }
.flaticon-court:before { content: "\f14d"; }
.flaticon-cross:before { content: "\f14e"; }
.flaticon-cross-1:before { content: "\f14f"; }
.flaticon-cross-2:before { content: "\f150"; }
.flaticon-cross-3:before { content: "\f151"; }
.flaticon-danger:before { content: "\f152"; }
.flaticon-danger-1:before { content: "\f153"; }
.flaticon-danger-2:before { content: "\f154"; }
.flaticon-days:before { content: "\f155"; }
.flaticon-days-1:before { content: "\f156"; }
.flaticon-exclamation:before { content: "\f157"; }
.flaticon-favorite:before { content: "\f158"; }
.flaticon-favorite-1:before { content: "\f159"; }
.flaticon-favorite-2:before { content: "\f15a"; }
.flaticon-favorite-3:before { content: "\f15b"; }
.flaticon-favorite-4:before { content: "\f15c"; }
.flaticon-favorite-5:before { content: "\f15d"; }
.flaticon-favorite-6:before { content: "\f15e"; }
.flaticon-favorite-7:before { content: "\f15f"; }
.flaticon-favorite-8:before { content: "\f160"; }
.flaticon-gear:before { content: "\f161"; }
.flaticon-gear-1:before { content: "\f162"; }
.flaticon-gps:before { content: "\f163"; }
.flaticon-gps-1:before { content: "\f164"; }
.flaticon-gps-2:before { content: "\f165"; }
.flaticon-hammer:before { content: "\f166"; }
.flaticon-hammer-1:before { content: "\f167"; }
.flaticon-hammer-2:before { content: "\f168"; }
.flaticon-hand:before { content: "\f169"; }
.flaticon-hand-1:before { content: "\f16a"; }
.flaticon-home:before { content: "\f16b"; }
.flaticon-interface:before { content: "\f16c"; }
.flaticon-interface-1:before { content: "\f16d"; }
.flaticon-interface-10:before { content: "\f16e"; }
.flaticon-interface-11:before { content: "\f16f"; }
.flaticon-interface-2:before { content: "\f170"; }
.flaticon-interface-3:before { content: "\f171"; }
.flaticon-interface-4:before { content: "\f172"; }
.flaticon-interface-5:before { content: "\f173"; }
.flaticon-interface-6:before { content: "\f174"; }
.flaticon-interface-7:before { content: "\f175"; }
.flaticon-interface-8:before { content: "\f176"; }
.flaticon-interface-9:before { content: "\f177"; }
.flaticon-justice:before { content: "\f178"; }
.flaticon-justice-1:before { content: "\f179"; }
.flaticon-law:before { content: "\f17a"; }
.flaticon-law-1:before { content: "\f17b"; }
.flaticon-law-2:before { content: "\f17c"; }
.flaticon-left:before { content: "\f17d"; }
.flaticon-left-1:before { content: "\f17e"; }
.flaticon-letter:before { content: "\f17f"; }
.flaticon-letter-1:before { content: "\f180"; }
.flaticon-line:before { content: "\f181"; }
.flaticon-line-1:before { content: "\f182"; }
.flaticon-line-2:before { content: "\f183"; }
.flaticon-line-3:before { content: "\f184"; }
.flaticon-line-4:before { content: "\f185"; }
.flaticon-line-5:before { content: "\f186"; }
.flaticon-link:before { content: "\f187"; }
.flaticon-link-1:before { content: "\f188"; }
.flaticon-link-2:before { content: "\f189"; }
.flaticon-link-3:before { content: "\f18a"; }
.flaticon-location:before { content: "\f18b"; }
.flaticon-location-1:before { content: "\f18c"; }
.flaticon-location-2:before { content: "\f18d"; }
.flaticon-location-3:before { content: "\f18e"; }
.flaticon-lock:before { content: "\f18f"; }
.flaticon-lock-1:before { content: "\f190"; }
.flaticon-lock-2:before { content: "\f191"; }
.flaticon-lock-3:before { content: "\f192"; }
.flaticon-lock-4:before { content: "\f193"; }
.flaticon-map-point:before { content: "\f194"; }
.flaticon-mark:before { content: "\f195"; }
.flaticon-mark-1:before { content: "\f196"; }
.flaticon-measure:before { content: "\f197"; }
.flaticon-medical:before { content: "\f198"; }
.flaticon-medical-1:before { content: "\f199"; }
.flaticon-multimedia:before { content: "\f19a"; }
.flaticon-nature:before { content: "\f19b"; }
.flaticon-note:before { content: "\f19c"; }
.flaticon-note-1:before { content: "\f19d"; }
.flaticon-open:before { content: "\f19e"; }
.flaticon-people:before { content: "\f19f"; }
.flaticon-people-1:before { content: "\f1a0"; }
.flaticon-people-2:before { content: "\f1a1"; }
.flaticon-people-3:before { content: "\f1a2"; }
.flaticon-people-4:before { content: "\f1a3"; }
.flaticon-people-5:before { content: "\f1a4"; }
.flaticon-people-6:before { content: "\f1a5"; }
.flaticon-people-7:before { content: "\f1a6"; }
.flaticon-phone:before { content: "\f1a7"; }
.flaticon-pin:before { content: "\f1a8"; }
.flaticon-pin-1:before { content: "\f1a9"; }
.flaticon-pin-2:before { content: "\f1aa"; }
.flaticon-pin-3:before { content: "\f1ab"; }
.flaticon-ribbon:before { content: "\f1ac"; }
.flaticon-ribbon-1:before { content: "\f1ad"; }
.flaticon-right:before { content: "\f1ae"; }
.flaticon-round:before { content: "\f1af"; }
.flaticon-scale:before { content: "\f1b0"; }
.flaticon-shape:before { content: "\f1b1"; }
.flaticon-shape-1:before { content: "\f1b2"; }
.flaticon-shapes:before { content: "\f1b3"; }
.flaticon-shapes-1:before { content: "\f1b4"; }
.flaticon-shapes-2:before { content: "\f1b5"; }
.flaticon-shapes-3:before { content: "\f1b6"; }
.flaticon-shapes-4:before { content: "\f1b7"; }
.flaticon-shapes-5:before { content: "\f1b8"; }
.flaticon-shapes-6:before { content: "\f1b9"; }
.flaticon-shapes-7:before { content: "\f1ba"; }
.flaticon-shop:before { content: "\f1bb"; }
.flaticon-shop-1:before { content: "\f1bc"; }
.flaticon-shop-2:before { content: "\f1bd"; }
.flaticon-shopping:before { content: "\f1be"; }
.flaticon-shopping-1:before { content: "\f1bf"; }
.flaticon-sign:before { content: "\f1c0"; }
.flaticon-sign-1:before { content: "\f1c1"; }
.flaticon-signs:before { content: "\f1c2"; }
.flaticon-signs-1:before { content: "\f1c3"; }
.flaticon-social:before { content: "\f1c4"; }
.flaticon-social-1:before { content: "\f1c5"; }
.flaticon-social-2:before { content: "\f1c6"; }
.flaticon-social-3:before { content: "\f1c7"; }
.flaticon-social-4:before { content: "\f1c8"; }
.flaticon-social-5:before { content: "\f1c9"; }
.flaticon-social-6:before { content: "\f1ca"; }
.flaticon-square:before { content: "\f1cb"; }
.flaticon-square-1:before { content: "\f1cc"; }
.flaticon-squares:before { content: "\f1cd"; }
.flaticon-star:before { content: "\f1ce"; }
.flaticon-suitcase:before { content: "\f1cf"; }
.flaticon-symbol:before { content: "\f1d0"; }
.flaticon-symbol-1:before { content: "\f1d1"; }
.flaticon-symbols:before { content: "\f1d2"; }
.flaticon-tag:before { content: "\f1d3"; }
.flaticon-tag-1:before { content: "\f1d4"; }
.flaticon-technology:before { content: "\f1d5"; }
.flaticon-technology-1:before { content: "\f1d6"; }
.flaticon-technology-10:before { content: "\f1d7"; }
.flaticon-technology-2:before { content: "\f1d8"; }
.flaticon-technology-3:before { content: "\f1d9"; }
.flaticon-technology-4:before { content: "\f1da"; }
.flaticon-technology-5:before { content: "\f1db"; }
.flaticon-technology-6:before { content: "\f1dc"; }
.flaticon-technology-7:before { content: "\f1dd"; }
.flaticon-technology-8:before { content: "\f1de"; }
.flaticon-technology-9:before { content: "\f1df"; }
.flaticon-telephone:before { content: "\f1e0"; }
.flaticon-telephone-1:before { content: "\f1e1"; }
.flaticon-telephone-2:before { content: "\f1e2"; }
.flaticon-telephone-3:before { content: "\f1e3"; }
.flaticon-three:before { content: "\f1e4"; }
.flaticon-three-1:before { content: "\f1e5"; }
.flaticon-time:before { content: "\f1e6"; }
.flaticon-time-1:before { content: "\f1e7"; }
.flaticon-tool:before { content: "\f1e8"; }
.flaticon-tool-1:before { content: "\f1e9"; }
.flaticon-tool-10:before { content: "\f1ea"; }
.flaticon-tool-11:before { content: "\f1eb"; }
.flaticon-tool-12:before { content: "\f1ec"; }
.flaticon-tool-13:before { content: "\f1ed"; }
.flaticon-tool-14:before { content: "\f1ee"; }
.flaticon-tool-2:before { content: "\f1ef"; }
.flaticon-tool-3:before { content: "\f1f0"; }
.flaticon-tool-4:before { content: "\f1f1"; }
.flaticon-tool-5:before { content: "\f1f2"; }
.flaticon-tool-6:before { content: "\f1f3"; }
.flaticon-tool-7:before { content: "\f1f4"; }
.flaticon-tool-8:before { content: "\f1f5"; }
.flaticon-tool-9:before { content: "\f1f6"; }
.flaticon-unlocked:before { content: "\f1f7"; }
.flaticon-unlocked-1:before { content: "\f1f8"; }
.flaticon-unlocked-2:before { content: "\f1f9"; }
.flaticon-unlocked-3:before { content: "\f1fa"; }
.flaticon-up-arrow:before { content: "\f1fb"; }
.flaticon-weapon:before { content: "\f1fc"; }
.flaticon-web:before { content: "\f1fd"; }
.flaticon-web-1:before { content: "\f1fe"; }
.flaticon-web-2:before { content: "\f1ff"; }